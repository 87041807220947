import React, { useEffect, useState } from "react";

function PreloadOpacity() {
  const [loaderHidden, onLoaderHiddenChange] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      onLoaderHiddenChange(true);
    }, 200);
  }, [onLoaderHiddenChange]);

  return (
    <div
      className={`container   bg-main flex-col loader   ${
        loaderHidden ? "loader--hidden" : ""
      }`}
    ></div>
  );
}

export default PreloadOpacity;
