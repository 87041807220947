import React, { useEffect, useState } from "react";

function Preload({ children }) {
  const [loaderHidden, onLoaderHiddenChange] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      onLoaderHiddenChange(false);
    }, 300);
  }, [onLoaderHiddenChange]);

  return (
    <>
      {loaderHidden ? (
        <div
          className={`container mx-auto d-flex lg:flex-row  flex-col loader ${
            !loaderHidden ? "loader--hidden" : ""
          }`}
        >
          <div className="lg:w-full flex w-2/3">
            <img className="mx-auto" src="/logo_loader.svg" alt=""></img>
          </div>
        </div>
      ) : (
        children
      )}
    </>
  );
}

export default Preload;
