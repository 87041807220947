import React, { useContext, useEffect } from "react";
import { ThemeContext } from "../App";
import Footer from "./Footer/Footer";
import NavBar from "./Navbar/NavBar";

export default function Layout({ children }) {
  const { activeLocal } = useContext(ThemeContext);

  useEffect(() => {
    const elements = document.querySelectorAll(".text-text");
    const elementActive = document.querySelector(".current");
    if (activeLocal === "dark") {
      elements.forEach((element) => {
        element.style.color = "white";
      });
      if (elementActive) {
        elementActive.style.color = "#2A82C3";
      }
    } else {
      elements.forEach((element) => {
        element.style.color = "#262626";
      });
      if (elementActive) {
        elementActive.style.color = "#274F9B";
      }
    }
  }, [activeLocal]);
  return (
    <div
      className={
        activeLocal === "dark"
          ? "bg-[#092145] text-white"
          : "bg-white text-main"
      }
    >
      <NavBar />

      {children}
      <Footer />
    </div>
  );
}
