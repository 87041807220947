import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { ThemeContext } from "../../App";
import Post from "../More/Post";

function Insights() {
  const { activeLocal } = useContext(ThemeContext);

  return (
    <>
      <div
        id="insight"
        className="container mx-auto mb-16"
        data-aos="fade-up"
        data-aos-anchor-placement="top-center"
      >
        <h2
          data-aos="flip-up"
          data-aos-offset="450"
          data-aos-duration="500"
          className={`text-item text-4xl font-vmedium section-title mb-12 text-center ${
            activeLocal === "dark" ? "text-dark" : "text-item"
          }`}
        >
          Latest News
        </h2>
        <Post></Post>
        <Link
          data-aos="flip-up"
          data-aos-offset="50"
          data-aos-duration="500"
          to="/blogs"
          className={`  ${
            activeLocal === "dark" ? "border-white" : "border-main"
          } relative float-right border-1 overflow-hidden py-1 lg:py-2`}
        >
          <span
            className={`  ${
              activeLocal === "dark"
                ? "slide-dark hover:text-main border-white"
                : "slide hover:text-white border-main"
            } w-full   px-14 py-4 uppercase text-sm font-vbold`}
          >
            See all
          </span>
        </Link>
      </div>
    </>
  );
}

export default Insights;
