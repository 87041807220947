import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { ThemeContext } from "../../App";
import Form from "./Form/Form";
import FormFireBase from "./Form/FormFireBase";
function Invest() {
  const { activeLocal } = useContext(ThemeContext);

  return (
    <section id="invest" className=" mx-auto">
      <div className="container mx-auto">
        <div className=" flex flex-col-reverse items-center lg:flex-row ">
          <div
            data-aos="fade-right"
            className="flex-auto lg:w-64 relative z-10 lg:-mt-20"
          >
            <>
              {/* title */}
              <div className="">
                <h1
                  className={`${
                    activeLocal === "dark" ? "text-dark" : "text-item"
                  } text-[32px] md:text-[52px] font-vbold   lg:leading-tight relative z-10 leading-10 lg:text-[62px] mb-6`}
                >
                  <span className="animation-5s">For the future</span>
                  <span className="md:mt-[14px] lg:mt-0 animation-6s block md:ml-[73px] lg:ml-[83px]">
                    The Decentralized Web
                  </span>
                </h1>
                <div
                  className="absolute w-[50%] -mt-[20px] lg:-mt-[32px] mr-[11px] top-0 right-0 z-1"
                  data-aos="fade-down"
                >
                  <img
                    src="images/OF.svg"
                    alt=""
                    className={`${activeLocal === "dark" ? "opacity-20" : ""} `}
                  />
                </div>
              </div>
            </>
            <div className=""></div>
          </div>

          <div
            data-aos="fade-left"
            data-aos-offset="400"
            className="flex-1 z-1 -mt-[1.5rem] md:w-[500px] transition-none lg:w-[500px] lg:h-[500px]"
          >
            <div
              style={{
                pointerEvents: "none",
              }}
            >
              <video
                className="transition-none lg:block md:block hidden"
                playsInline
                autoPlay
                loop
                muted
                width="100%"
                height="100%"
              >
                <source src="video/banner1.webm" type="video/webm"></source>
              </video>

              <video
                key={activeLocal === "dark" ? "dark1" : "banner1_cut"}
                className="transition-none lg:hidden md:hidden block"
                playsInline
                autoPlay
                loop
                muted
                width="100%"
                height="100%"
              >
                <source
                  src={`video/${
                    activeLocal === "dark" ? "dark1" : "banner1_cut"
                  }.mp4`}
                  type="video/mp4"
                ></source>
              </video>
            </div>
          </div>
        </div>
        <div
          data-aos="fade-up"
          className="lg:absolute top-0 lg:mt-[540px] lg:w-[640px] z-0"
        >
          <div
            className=""
            style={{
              pointerEvents: "none",
            }}
          >
            <video
              className="transition-none lg:block md:block hidden"
              muted
              loop
              playsInline
              autoPlay
              width="100%"
              height="100%"
            >
              <source src="video/banner2.webm" type="video/mp4"></source>
            </video>

            <video
              key={activeLocal === "dark" ? "dark2" : "banner2"}
              className="transition-none lg:hidden md:hidden block"
              muted
              loop
              playsInline
              autoPlay
              width="100%"
              height="100%"
            >
              <source
                src={`video/${
                  activeLocal === "dark" ? "dark2" : "banner2"
                }.mp4`}
              ></source>
            </video>
          </div>
        </div>
      </div>

      {/* Introduction */}

      <div className="lg:mt-40 pb-8 relative">
        <div className="container mx-auto">
          <div className="lg:flex items-center gap-x-8">
            <div
              className="lg:pr-16 mt-11 lg:w-2/4"
              data-aos="fade-up"
              data-aos-anchor-placement="top-center"
            >
              <div className="fund blur__bg-mobile"></div>
              <div className="mb-8">
                <h2
                  className={`section-title font-vmedium ${
                    activeLocal === "dark" ? " text-dark" : "text-item"
                  }`}
                >
                  CryptoViet Ventures
                </h2>
                <p
                  data-aos="fade-right"
                  data-aos-offset="300"
                  data-aos-duration="500"
                  className="text-text font-vlight text-base lg:text-xl"
                >
                  Established in 2019, CryptoViet Ventures is the heart of the
                  CryptoViet Info ecosystem and a venture firm in the
                  Decentralized Web.
                </p>
              </div>
              {/* <div className="mb-12">
                <h2
                  className={`section-title font-vmedium ${
                    activeLocal === "dark" ? "text-dark" : " text-item"
                  }`}
                >
                  What we do?
                </h2>
                <p
                  data-aos="fade-right"
                  data-aos-offset="200"
                  data-aos-duration="500"
                  className="text-text font-vlight text-base  lg:text-xl"
                >
                  Since 2017, we have started to build a network of partnerships
                  with many blockchain companies around the world, especially in
                  Vietnam, both in media and technology.
                </p>
              </div> */}
              <div
                className={`blur__bg-fund hidden  ${
                  activeLocal === "dark" ? "lg:block" : ""
                }`}
              ></div>

              <div className="flex w-full justify-between relative md:container md:pr-16 lg:pr-0 md:mb-12 lg:mb-0 items-center">
                <div className="z-10 ">
                  <Link target="_blank" to="https://grindy.io/" title="Grindy">
                    <img
                      className="relative opacity-0 lg:opacity-100"
                      src="/images/logo_prj/grindy.svg "
                      alt="project"
                    ></img>
                    <img
                      className="w-2/6 logo-fund absolute top-0 opacity-1 lg:opacity-0"
                      src="/images/logo_prj/grindy1.svg"
                      alt="project"
                    ></img>
                  </Link>
                </div>
                <div className="z-10">
                  <Link
                    target="_blank"
                    to="https://cryptoviet.ventures/"
                    title="CryptoViet Ventures"
                  >
                    <img
                      className="relative  opacity-0 lg:opacity-100"
                      src="/images/logo_prj/cv.svg"
                      alt="project"
                    ></img>
                    <img
                      className="w-2/6 logo-fund absolute top-0 animate-none lg:animate-pulse opacity-1 lg:opacity-0"
                      src="/images/logo_prj/ccv2.png"
                      alt="project"
                    ></img>
                  </Link>
                </div>
                <div className="z-10">
                  <Link
                    target="_blank"
                    to="https://cryptoviet.info/"
                    title="Analytic CryptoViet"
                  >
                    <img
                      className="relative opacity-0 lg:opacity-100"
                      src="/images/logo_prj/cvv.svg"
                      alt="project"
                    ></img>
                    <img
                      className=" w-2/6 logo-fund absolute top-0 opacity-1 md:opacity-1 lg:opacity-0"
                      src="/images/logo_prj/cvv1.svg"
                      alt="project"
                    ></img>
                  </Link>
                </div>
              </div>
              <p
                data-aos="fade-right"
                data-aos-offset="100"
                data-aos-duration="500"
                className="text-text font-vlight text-base lg:text-xl mt-10 relative z-50"
              >
                We back on{" "}
                <span className="font-vmedium text-text">
                  THE MOST BRILLIANT FOUNDERS
                </span>{" "}
                to accelerate the adoption of the Blockchain industry to real
                life.
              </p>
            </div>

            <div
              className="lg:w-2/4"
              data-aos="fade-left"
              data-aos-offset="500"
              data-aos-duration="600"
            >
              <FormFireBase />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Invest;
