import React from "react";
import ScrollToTop from "../components/More/ScrollToTop";
import Portfolio from "../components/Portfolio/Portfolio";
import Layout from "../layouts/Layout";

function PortfolioScreen() {
  return (
    <div>
      <Layout>
        <ScrollToTop />
        <Portfolio></Portfolio>
      </Layout>
    </div>
  );
}

export default PortfolioScreen;
