import React, { useContext } from "react";
import { FaLinkedinIn, FaTwitter } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

import { ThemeContext } from "../../App";
import ListPortfolio from "../../data/PortfolioData";
import ScrollToTop from "../More/ScrollToTop";
function Portfolio() {
  const { activeLocal } = useContext(ThemeContext);

  return (
    <>
      <ScrollToTop />
      <div className="container mx-auto lg:mb-60">
        <div className="flex lg:flex-row flex-col-reverse mb-16">
          <div className="flex-1 mt-12 lg:mt-2 text-center">
            <h2
              data-aos="flip-up"
              data-aos-offset="300"
              data-aos-duration="500"
              className={`text-item  font-vmedium section-title  text-center ${
                activeLocal === "dark" ? " text-dark" : "text-item"
              }`}
            >
              Portfolio
            </h2>
            <p
              data-aos="fade-right"
              data-aos-offset="300"
              data-aos-duration="500"
              className="lg:w-4/6 w-full mx-auto flex-1 font-vlight -mt-6 relative z-10 text-base lg:text-xl text-text"
            >
              We incubate, acquire, & operate startups/projects related to
              Decentralized Web with technical, operational and capital support.
              With the principle of "
              <span className="italic">long-term, necessary & expanding</span>".
            </p>
          </div>
        </div>

        <div className="hover__shiny relative  lg:grid xl:grid-cols-3 lg:grid-cols-2 grid-cols-1 gap-x-7 gap-y-4 ">
          {ListPortfolio.slice()
            .reverse()
            .map((item) => (
              <div
                data-aos="fade-right"
                data-aos-offset="150"
                data-aos-duration="500"
                className={`cursor-default overflow-hidden rounded-2xl relative  min-h-[172px] px-4 mb-6  py-2 z-40  pt-6 lg:pb-4 grid gap-10 grid-cols-3 ${
                  activeLocal === "dark"
                    ? "linear__bg-dark"
                    : "linear__bg-portfolio"
                }`}
                key={item.id}
              >
                <div className="relative z-10 ">
                  <a target="_blank" href={item.href} rel="noreferrer">
                    <div className="flex flex-col justify-center h-24">
                      <img
                        src={item.logo}
                        alt={item.name}
                        className={` img_fix col-span-1 relative z-20`}
                      />
                    </div>
                  </a>

                  <div className="bg-slate-200 ">
                    <span className="span_fix absolute left-0 mt-2 md:ml-10 lg:ml-0 w-24 flex">
                      <a
                        href={item.tweet}
                        target="_blank"
                        rel="noreferrer"
                        className="block  w-2/5"
                      >
                        <FaXTwitter
                          className={`${
                            activeLocal === "dark" ? "" : "text-[#828282]"
                          } w-6 h-6 hover:text-[#1DA1F2]`}
                        />
                      </a>

                      <a
                        href={item.linkedin}
                        target="_blank"
                        rel="noreferrer"
                        className="w-2/5  block "
                      >
                        <FaLinkedinIn
                          className={`${
                            activeLocal === "dark" ? "" : "text-[#828282]"
                          } w-6 h-6 hover:text-[#1DA1F2]`}
                        />
                      </a>
                    </span>
                  </div>
                </div>
                <span className="span1"></span>

                <div className="content col-span-2 -ml-10 pr-2 ">
                  <h2 className="transition-3  relative  text-text mb-2  font-vmedium">
                    <a href={item.href} target="_blank" rel="noreferrer">
                      {item.name}
                    </a>
                  </h2>
                  <p className="relative transition-3 text-text text-sm font-vlight">
                    {item.content}
                  </p>
                </div>
              </div>
            ))}
          <span></span>
        </div>
      </div>
      <div className="blur__bg-portfolio lg:block hidden"></div>

      <div className="absolute -right-20 lg:w-[90%] md:w-[90%]  top-1/2 lg:top-[30%] z-0">
        <video
          muted
          loop
          autoPlay
          playsInline
          width="100%"
          height="100%"
          className="transition-none lg:block md:block hidden"
        >
          <source src="video/banner3.webm" type="video/webm"></source>
        </video>
        <video
          // key={activeLocal === "dark" ? " dark3" : " banner3"}
          muted
          loop
          autoPlay
          playsInline
          width="100%"
          height="100%"
          type="video/mp4"
          className="lg:hidden md:hidden block"
        >
          <source
            // src={`video/${activeLocal === "dark" ? "dark3" : "banner3"}.mp4`}
            src="video/banner3.mp4"
            type="video/mp4"
          ></source>
        </video>
      </div>
    </>
  );
}

export default Portfolio;
