import React, { useContext } from "react";
import { ThemeContext } from "../../App";
import Layout from "../../layouts/Layout";
import ScrollToTop from "../More/ScrollToTop";

function Policy() {
  const { activeLocal } = useContext(ThemeContext);
  return (
    <Layout>
      <ScrollToTop />
      <div className="container mx-auto mt-20">
        <h2
          data-aos="flip-up"
          data-aos-offset="100"
          data-aos-duration="300"
          className={`  font-vmedium section-title mb-16  ${
            activeLocal === "dark" ? " text-dark" : "text-item"
          }`}
        >
          Privacy Policy
        </h2>
        <div className="mt-10">
          <h2 className="text-text font-vbold mb-8">
            V 2.2 – Last updated 23 February 2023
          </h2>

          <p className="text-text font-vlight mb-6 text-justify">
            Welcome to CryptoViet Ventures’s privacy policy. Your privacy is
            important to us and we are committed to protecting your personal
            data. This privacy policy will inform you as to how we look after
            your personal data when you visit our website (regardless of where
            you visit it from) and when you use our products and services. It
            will also tell you about your rights and how you can contact us if
            you have any concerns.
          </p>

          <div>
            <h2 className="text-text text-2xl font-vbold mb-8">
              Purpose of this privacy policy
            </h2>
            <p className="text-text font-vlight mb-6 text-justify">
              This privacy policy aims to give you information on how CVV
              collects and processes your personal data through your use of this
              website, including any data you may provide through this website
              when you sign up to our newsletter or apply to one of our
              accelerator programs.
            </p>

            <p className="text-text font-vlight mb-6 text-justify">
              This privacy policy aims to give you information on how CVV
              collects and processes your personal data through your use of this
              website, including any data you may provide through this website
              when you sign up to our newsletter or apply to one of our
              accelerator programs.
            </p>

            <p className="text-text font-vlight mb-6 text-justify">
              CVV means CryptoViet Ventures Operations Ltd, a UK registered
              company. We are controllers and responsible for the personal data
              we collect. We might share information with our subsidiaries and
              affiliated companies in accordance with this policy (collectively
              referred to as “CVV”, “we”, “us” or “our” in this privacy policy).
            </p>

            <p className="text-text font-vlight mb-6 text-justify">
              This website is not intended for children and we do not knowingly
              collect data relating to children. We might provide you with
              additional privacy information when accessing specific products or
              services and it is important that you read this privacy policy and
              any other information we provide carefully so that you are fully
              aware of how and why we are using your data.
            </p>

            <p className="text-text font-vlight mb-6 text-justify">
              If you have any questions about this privacy policy or our privacy
              practices, please contact us via contact@cryptoviet.ventures You
              have the right to make a complaint at any time to the Information
              Commissioner’s Office (ICO), the UK regulator for data protection
              issues. We would, however, appreciate the chance to deal with your
              concerns before you approach the ICO so please contact us in the
              first instance.
            </p>
          </div>

          <div>
            <h2 className="text-text text-2xl font-vbold mb-8">
              Changes to the privacy policy and your duty to inform us of
              changes
            </h2>
            <p className="text-text font-vlight mb-6 text-justify">
              We keep our privacy policy under regular review. This version was
              last updated on 27 January 2023. It is important that the personal
              data we hold about you is accurate and current. Please keep us
              informed if your personal data changes during your relationship
              with us.
            </p>
          </div>

          <div>
            <h2 className="text-text text-2xl font-vbold mb-8">
              Third-party links
            </h2>
            <p className="text-text font-vlight mb-6 text-justify">
              This website may include links to third-party websites, plug-ins
              and applications. Clicking on those links or enabling those
              connections may allow third parties to collect or share data about
              you. We do not control these third-party websites and are not
              responsible for their privacy statements. When you leave our
              website, we encourage you to read the privacy policy of every
              website you visit.
            </p>
          </div>

          <div>
            <h2 className="text-text text-2xl font-vbold mb-8">
              The data we collect about you
            </h2>
            <p className="text-text font-vlight mb-6 text-justify">
              Personal data does not include data where the identity has been
              removed (anonymous data). We may collect, use, store and transfer
              different kinds of personal data about you which we have grouped
              together as follows:
            </p>

            <ul class="list-disc pl-5">
              <li>
                Identity Data includes full name, username or similar
                identifier.
              </li>
              <li>
                Contact Data includes residential address and email address.
              </li>
              <li>
                KYC Data includes personal data obtained from:
                <ul class="list-disc pl-5">
                  <li>
                    Identity verification (ID document information and proof of
                    address);
                  </li>
                  <li>
                    Verification of ultimate beneficial ownership of corporate
                    and other legal entities;
                  </li>
                  <li>
                    Anti-money laundering, proceeds of crime and terrorist
                    finance checks (AML/CFT checks);
                  </li>
                  <li>Sanctions lists checks.</li>
                </ul>
              </li>
              <li>
                Financial Data includes bank account details and other financial
                information, including but not limited to, source of funds and
                source of wealth information.
              </li>
              <li>
                Transaction data includes details about payments to or from you,
                including digital access wallet information.
              </li>
              <li>
                Technical Data includes IP address, browser type and version,
                time zone setting and location, browser plug-in types and
                versions, operating system and platform, and other technology on
                the devices you use to access this website.
              </li>
              <li>
                Usage Data includes information about how you use our website,
                products and services.
              </li>
              <li>
                Communications Data includes your preferences in receiving
                marketing from us.
              </li>
            </ul>

            <p className="text-text font-vlight mb-6 text-justify">
              As part of our Due Diligence process we may choose to use a third
              party KYC provider to collect and verify the KYC data. Such
              verification shall include AML/CFT checks and ongoing monitoring.
              Your information will be collected in accordance with the service
              provider’s privacy policy and with your consent.
            </p>
            <p className="text-text font-vlight mb-6 text-justify">
              We may also collect and process Special Categories of Personal
              Data about you (this includes data concerning your health,
              personal data revealing your racial or ethnic origin, political
              opinions, religious or philosophical beliefs, trade union
              membership, genetic or biometric data) and/or information about
              criminal convictions and offences (together “Special Category
              Data”) when undertaking KYC/AML checks as part of our client
              on-boarding process.
            </p>
            <p className="text-text font-vlight mb-6 text-justify">
              We also collect and use Aggregated Data such as statistical or
              demographic data for any purpose. Aggregated Data could be derived
              from your personal data but is not considered personal data in law
              as this data will not directly or indirectly reveal your identity.
              For example, we may aggregate your Usage Data to calculate the
              percentage of users accessing a specific website feature. However,
              if we combine or connect Aggregated Data with your personal data
              so that it can directly or indirectly identify you, we treat the
              combined data as personal data which will be used in accordance
              with this privacy policy.
            </p>
          </div>
          <div>
            <h2 className="text-text text-2xl font-vbold mb-8">
              If you fail to provide personal data
            </h2>
            <p className="text-text font-vlight text-justify">
              Where we need to collect personal data by law, or under the terms
              of a contract we have with you, and you fail to provide that data
              when requested, we may not be able to perform the contract we have
              or are trying to enter into with you (for example, to provide you
              services). In this case, we may have to cancel a product or
              service you have with us but we will notify you if this is the
              case at the time. How is your personal data collected? <br></br>
              We use different methods to collect data from and about you
              including through:
            </p>
            <ul class="list-disc pl-5">
              <li>
                Direct interactions. You may give us your information by filing
                a form, signing up to our services or newsletter, signing a
                contract with us, via email or social media communications,
                interacting directly with a member of our team, be it in person
                or electronically, or providing services to us;
              </li>
              <li>
                Automated technologies or interactions. As you interact with our
                website, we will automatically collect Technical Data about your
                equipment, browsing actions and patterns. We collect this
                personal data by using cookies and other similar technologies.
              </li>
            </ul>
            <p className="text-text font-vlight mb-6 text-justify">
              Third parties of public available sources. We may receive personal
              data about you from third parties such as analytics providers
              (Technical Data), KYC and other service providers and public
              records.
            </p>
          </div>

          <div>
            <h2 className="text-text text-2xl font-vbold mb-8">
              How we use your personal data
            </h2>
            <p className="text-text font-vlight text-justify">
              We will only use your personal data where:
            </p>
            <ul class="list-disc pl-5">
              <li>
                We need to perform the contract we are about to enter into or
                have entered into with you.
              </li>
              <li>We have your permission to do so.</li>
              <li>
                It is necessary for our legitimate interests (or those of a
                third party) and your interests and fundamental rights do not
                CVVerride those interests.
              </li>
              <li>
                We need to comply with a legal obligation. Where we process
                Special Category Data, we only do so with your explicit consent.
              </li>
            </ul>
          </div>

          <div>
            <h2 className="text-text text-2xl font-vbold mb-8">
              Purposes for which we will use your personal data
            </h2>
            <p className="text-text font-vlight mb-6 text-justify">
              We have set out below, in a table format, a description of all the
              ways we plan to use your personal data, and which of the legal
              bases we rely on to do so. We have also identified what our
              legitimate interests are where appropriate.
            </p>

            <p className="text-text font-vlight mb-6 text-justify">
              Note that we may process your personal data for more than one
              lawful ground depending on the specific purpose for which we are
              using your data. Please contact us if you need details about the
              specific legal ground we are relying on to process your personal
              data where more than one ground has been set out in the table
              below.
            </p>
          </div>

          <div className="mx-auto w-2/3">
            <img src="/images/policy1.svg" alt="" />
            <img src="/images/policy2.svg" alt="" />
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Policy;
