import React, { useContext } from "react";
import { ThemeContext } from "../../App";
import Layout from "../../layouts/Layout";
import ScrollToTop from "../More/ScrollToTop";

function Login() {
  const { activeLocal } = useContext(ThemeContext);
  return (
    <Layout>
      <ScrollToTop />
      <div className="container mx-auto">
        <div className="flex lg:flex-row flex-col-reverse mb-20">
          <div className="flex-1 lg:mt-20 mt-16 text-center">
            <h2
              data-aos="flip-up"
              data-aos-offset="300"
              data-aos-duration="500"
              className={`text-item  font-vmedium section-title mb-12 text-center ${
                activeLocal === "dark" ? " text-dark" : "text-item"
              }`}
            >
              CryptoViet Login
            </h2>
            <p
              data-aos="fade-right"
              data-aos-offset="300"
              data-aos-duration="500"
              className="lg:w-4/6 w-full mx-auto flex-1 font-vmedium relative text-base lg:text-xl text-text"
            >
              View job opening coming soon !
            </p>
          </div>
        </div>
        <div className="lg:w-4/6 mx-auto -mt-20">
          <div
            data-aos="fade-left"
            data-aos-offset="300"
            className="flex-1 z-1 mt-16 "
          >
            <div
              style={{
                pointerEvents: "none",
              }}
            >
              <video
                className="transition-none lg:block md:block hidden"
                muted
                loop
                autoPlay
                playsInline
                width="100%"
                height="100%"
                type="video/mp4"
              >
                <source
                  src="/video/logosoon.webm"
                  type='video/webm; codecs="vp8, vorbis"'
                ></source>
              </video>

              <video
                key={activeLocal === "dark" ? "darkcareers" : "BiglogoOnly"}
                className="transition-none lg:hidden md:hidden block"
                muted
                loop
                autoPlay
                playsInline
                width="100%"
                height="100%"
                type="video/mp4"
              >
                <source
                  src={`video/${
                    activeLocal === "dark" ? "darkcareers" : "BiglogoOnly"
                  }.mp4`}
                  type="video/mp4"
                ></source>
              </video>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Login;
