import React, { useContext } from "react";
import { FaLinkedinIn, FaTwitter } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { ThemeContext } from "../../App";
import ListPortfolio from "../../data/PortfolioData";
function Portfolio() {
  const { activeLocal } = useContext(ThemeContext);

  return (
    <>
      <div id="portfolio" className="relative">
        <div className="portfolio lg:block hidden "></div>
        <div className="relative ">
          <div className="container mx-auto mb-40 lg:mt-20">
            <div>
              <div className="absolute -right-20 lg:w-[80%] top-56 lg:top-22">
                <video
                  className="transition-none lg:block md:block hidden"
                  muted
                  loop
                  playsInline
                  autoPlay
                  width="100%"
                  height="100%"
                >
                  <source
                    src="video/banner3.webm"
                    type='video/webm; codecs="vp8, vorbis"'
                  ></source>
                </video>

                <video
                  key={activeLocal === "dark" ? "dark3" : "banner3"}
                  className="transition-none lg:hidden md:hidden block"
                  muted
                  loop
                  playsInline
                  autoPlay
                  width="100%"
                  height="100%"
                  type="video/mp4"
                >
                  <source
                    src={`video/${
                      activeLocal === "dark" ? "dark3" : "banner3"
                    }.mp4`}
                    type="video/mp4"
                  ></source>
                </video>
              </div>
              <div
                className="mb-12"
                data-aos="fade-up"
                data-aos-anchor-placement="top-center"
              >
                <h2
                  className={`text-right section-title lg:mt-0 mt-16 font-vmedium mb-6 ${
                    activeLocal === "dark" ? " text-dark" : "text-item"
                  }`}
                >
                  Our Portfolio
                </h2>
                <div className="lg:flex mb-4">
                  <div className="w-[25%]"></div>
                  <p className="flex-1 lg:w-[75%] pl-3 lg:text-right  font-vlight relative text-base lg:text-xl text-text">
                    We incubate, acquire & operate startups/projects related to
                    Decentralized Web with technical, operational and capital
                    support. With the principle of "
                    <span className="italic">
                      long-term, necessary & expanding
                    </span>
                    ".
                  </p>
                </div>
              </div>

              <div
                data-aos="fade-right"
                data-aos-offset="400"
                data-aos-duration="600"
                className="hover__shiny  relative hidden z-10  lg:grid xl:grid-cols-3 lg:grid-cols-2 grid-cols-1 gap-x-7 gap-y-4 "
              >
                {ListPortfolio.slice()
                  .reverse()
                  .slice(0, 9)
                  .map((item, i) => (
                    <div
                      // data-aos="new-animation"
                      // data-aos-offset="300"
                      // data-aos-duration="500"
                      className={`cursor-default overflow-hidden  min-h-[172px]  px-4  lg:pt-6 lg:pb-4 z-10  ${
                        activeLocal === "dark"
                          ? "linear__bg-dark"
                          : "linear__bg-portfolio "
                      }  rounded-2xl  grid gap-10 grid-cols-3`}
                      key={i}
                    >
                      <div className="relative">
                        <a target="_blank" href={item.href} rel="noreferrer">
                          <div className="flex flex-col justify-center h-24">
                            <img
                              src={item.logo}
                              alt={item.name}
                              className={`img_hover col-span-1 mt-2 h-24 transition-3`}
                            />
                          </div>
                        </a>
                        <div className="bg-slate-200 ">
                          <span className="span2 transition-3 absolute left-0 mt-2  ml-2 w-24  flex">
                            <a
                              href={item.tweet}
                              target="_blank"
                              rel="noreferrer"
                              className=" block  w-2/5"
                            >
                              <FaXTwitter
                                className={`w-6 h-6 ${
                                  activeLocal === "dark" ? "" : "text-[#828282]"
                                } hover:text-[#1DA1F2] `}
                              />
                            </a>

                            <a
                              href={item.linkedin}
                              target="_blank"
                              rel="noreferrer"
                              className="w-2/5  block "
                            >
                              <FaLinkedinIn
                                className={`w-6 h-6  ${
                                  activeLocal === "dark" ? "" : "text-[#828282]"
                                } hover:text-[#1DA1F2]`}
                              />
                            </a>
                          </span>
                        </div>
                      </div>
                      <span className="span1"></span>

                      <div className="content col-span-2 -ml-7 pr-2">
                        <h2 className="transition-3  relative mb-2 text-text font-vmedium">
                          <a href={item.href} target="_blank" rel="noreferrer">
                            {item.name}
                          </a>
                        </h2>
                        <p className="relative transition-3 text-text text-sm font-vlight">
                          {item.content}
                        </p>
                      </div>
                    </div>
                  ))}
                <span></span>
              </div>

              <div
                data-aos="fade-right"
                data-aos-offset="500"
                data-aos-duration="500"
                className="hover__shiny relative lg:hidden xl:grid-cols-3 lg:grid-cols-2 grid-cols-1 z-10 gap-x-7 gap-y-4  "
              >
                {ListPortfolio.slice()
                  .reverse()
                  .slice(0, 4)
                  .map((item) => (
                    <div
                      data-aos="fade-right"
                      data-aos-offset="200"
                      data-aos-duration="500"
                      className={`cursor-default overflow-hidden rounded-2xl relative  min-h-[160px] px-4 mb-6  py-2 z-40  pt-6 lg:pb-4 grid gap-10 grid-cols-3 ${
                        activeLocal === "dark"
                          ? "linear__bg-dark"
                          : "linear__bg-portfolio"
                      }`}
                      key={item.id}
                    >
                      <div className="relative z-10 ">
                        <a target="_blank" href={item.href} rel="noreferrer">
                          <img
                            src={item.logo}
                            alt={item.name}
                            className={` img_fix col-span-1 relative z-50 h-24 `}
                          />
                        </a>

                        <div className="bg-slate-200 ">
                          <span className="span_fix absolute left-0 mt-2 md:ml-10 w-24 flex">
                            <a
                              href={item.tweet}
                              target="_blank"
                              rel="noreferrer"
                              className="block  w-2/5"
                            >
                              <FaTwitter
                                className={`${
                                  activeLocal === "dark" ? "" : "text-[#828282]"
                                } w-6 h-6 hover:text-[#1DA1F2]`}
                              />
                            </a>

                            <a
                              href={item.linkedin}
                              target="_blank"
                              rel="noreferrer"
                              className="w-2/5  block "
                            >
                              <FaLinkedinIn
                                className={`${
                                  activeLocal === "dark" ? "" : "text-[#828282]"
                                } w-6 h-6 hover:text-[#1DA1F2]`}
                              />
                            </a>
                          </span>
                        </div>
                      </div>
                      <span className="span1"></span>

                      <div className="content col-span-2 -ml-10 pr-2 ">
                        <h2 className="transition-3  relative  text-text mb-2  font-vmedium">
                          <a href={item.href} target="_blank" rel="noreferrer">
                            {item.name}
                          </a>
                        </h2>
                        <p className="relative transition-3 text-text text-sm font-vlight">
                          {item.content}
                        </p>
                      </div>
                    </div>
                  ))}
                <span></span>
              </div>
              <Link
                data-aos="fade-left"
                data-aos-offset="150"
                data-aos-duration="500"
                to="/portfolio"
                className={`  ${
                  activeLocal === "dark" ? "border-white" : "border-main"
                } relative float-right border-1 overflow-hidden py-1 lg:py-2`}
              >
                <span
                  className={`  ${
                    activeLocal === "dark"
                      ? "slide-dark hover:text-main border-white"
                      : "slide hover:text-white border-main"
                  } w-full   px-14 py-4 uppercase text-sm font-vbold`}
                >
                  See all
                </span>
              </Link>
            </div>

            <div className="blur__bg-portfolio lg:block hidden"></div>
            <div className="pf blur__bg-mobile lg:hidden block"></div>
          </div>
        </div>
        <div className="portfolio__mobile lg:hidden block"></div>
      </div>
    </>
  );
}

export default Portfolio;
