export const LogoHeader = ({ className, width, height, activeLocal }) => {
  return (
    <svg
      width="240"
      height="60"
      viewBox="0 0 303 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_34_15094)">
        <path
          d="M164.889 40.201L173.734 52.0438V40.201H175.985V55.6074H173.894L165.05 43.883V55.6074H162.799V40.201H164.889Z"
          fill={activeLocal === "dark" ? "#5AB1F2" : "#2A82C3"}
        />
        <path
          d="M148.23 55.6074V40.0179H159.433V42.1712H150.589V46.7145H158.769V48.7924H150.589V53.4003H159.433V55.5536L148.23 55.6074Z"
          fill={activeLocal === "dark" ? "#5AB1F2" : "#2A82C3"}
        />
        <path
          d="M225.353 55.6074V40.201H236.352V42.2789H227.604V46.8114H235.698V48.8893H227.604V53.5295H236.352V55.6074H225.353Z"
          fill={activeLocal === "dark" ? "#5AB1F2" : "#2A82C3"}
        />
        <path
          d="M135.998 55.6935H138.067L144.939 40.0179H142.494L137.038 52.9589L131.581 40.0179H129.223L135.998 55.6935Z"
          fill={activeLocal === "dark" ? "#5AB1F2" : "#2A82C3"}
        />
        <path
          d="M247.114 47.1021L245.228 46.7253C243.255 46.3484 242.226 45.6487 242.226 44.3567C242.226 42.8494 243.298 42.2035 245.228 42.2035C247.157 42.2035 248.24 42.871 248.444 44.0983L250.137 42.3973C249.291 40.9761 247.693 40.1256 245.335 40.1256C242.044 40.1256 239.975 41.7405 239.975 44.4321C239.975 46.7038 241.475 48.028 244.263 48.6847L246.139 49.0615C248.026 49.4384 248.776 50.2027 248.776 51.3332C248.776 52.8405 247.457 53.691 245.388 53.691C243.319 53.691 241.904 52.9374 241.722 51.5378L240.028 53.2388C240.875 54.8538 242.848 55.7043 245.388 55.7043C248.68 55.7043 250.942 54.1863 250.942 51.2579C251.081 49.0938 249.934 47.7588 247.114 47.1021Z"
          fill={activeLocal === "dark" ? "#5AB1F2" : "#2A82C3"}
        />
        <path
          d="M179.565 42.1819V40.201H191.508V42.1819H186.759V55.6935H184.4V42.2789L179.608 42.1819H179.565Z"
          fill={activeLocal === "dark" ? "#5AB1F2" : "#2A82C3"}
        />
        <path
          d="M204.684 40.201H207.032V50.1705C207.076 51.1726 206.815 52.1646 206.281 53.0128C205.801 53.8678 205.045 54.5326 204.137 54.8969C203.137 55.3851 202.032 55.6146 200.921 55.5643C199.811 55.6019 198.709 55.3731 197.705 54.8969C196.867 54.4261 196.137 53.7843 195.561 53.0128C195.013 52.1705 194.746 51.175 194.8 50.1705V40.1579H197.062V49.9874C197.074 50.6433 197.235 51.2878 197.533 51.8715C197.844 52.4257 198.3 52.8838 198.852 53.1958C199.462 53.5098 200.139 53.6723 200.824 53.6695C201.51 53.6723 202.187 53.5098 202.797 53.1958C203.37 52.9119 203.833 52.4465 204.116 51.8715C204.43 51.2939 204.592 50.6455 204.587 49.9874L204.684 40.1579V40.201Z"
          fill={activeLocal === "dark" ? "#5AB1F2" : "#2A82C3"}
        />
        <path
          d="M210.419 55.6935V40.201H215.876C216.891 40.1411 217.902 40.3726 218.792 40.8685C219.573 41.1948 220.209 41.7957 220.582 42.5588C220.966 43.3535 221.16 44.2271 221.15 45.1103C221.163 45.9939 220.968 46.868 220.582 47.662C220.161 48.3907 219.539 48.9816 218.792 49.363C217.871 49.7605 216.877 49.9551 215.876 49.9336H211.738V47.9526H215.704C216.342 47.9734 216.978 47.8749 217.58 47.662C218.038 47.4899 218.419 47.1572 218.652 46.7253C218.885 46.2523 219.013 45.7345 219.027 45.2073C219.046 44.6794 218.916 44.157 218.652 43.7C218.399 43.2499 218.028 42.8776 217.58 42.6234C216.986 42.3727 216.348 42.2445 215.704 42.2466H212.788V55.7581L210.43 55.6612L210.419 55.6935ZM218.041 48.7063L221.804 55.6935H219.167L215.404 48.7063H218.041Z"
          fill={activeLocal === "dark" ? "#5AB1F2" : "#2A82C3"}
        />
        <path
          d="M26.4364 30.3284L13.1753 7.46097H39.4187L43.471 14.7389L51.8436 0H0L26.0612 45.1642L34.4338 30.5222L26.4364 30.3284Z"
          fill={activeLocal === "dark" ? "white" : "#262626"}
        />
        <path
          d="M60.7741 0L30.3926 52.8189L34.5306 60L69.2431 0H60.7741Z"
          fill="url(#paint0_linear_34_15094)"
        />
        <path
          d="M126.125 12.4242C126.125 7.69782 123.016 4.19879 116.809 4.19879H105.799V28.2935H111.255V20.6388H114.364L120.196 28.2935H126.307L120.1 20.1759C121.862 19.8222 123.439 18.8453 124.545 17.4228C125.65 16.0003 126.211 14.2266 126.125 12.4242ZM116.476 15.7294H111.395V9.11895H116.476C119.199 9.11895 120.614 10.3463 120.614 12.4242C120.614 14.5021 119.113 15.7294 116.476 15.7294Z"
          fill={activeLocal === "dark" ? "white" : "#262626"}
        />
        <path
          d="M139.761 14.4052L133.833 4.19882H127.529L136.942 19.7021V28.2936H142.398V19.6052L151.897 4.19882H145.69L139.761 14.4052Z"
          fill={activeLocal === "dark" ? "white" : "#262626"}
        />
        <path
          d="M165.319 4.19879H154.395V28.2935H159.851V20.8326H165.211C171.322 20.8326 174.431 17.3336 174.431 12.5211C174.431 7.70858 171.418 4.19879 165.308 4.19879M164.836 15.8263H159.958V9.11895H164.858C167.484 9.11895 168.899 10.2494 168.899 12.4242C168.899 14.599 167.484 15.8263 164.858 15.8263"
          fill={activeLocal === "dark" ? "white" : "#262626"}
        />
        <path
          d="M176.735 9.21587H183.789V28.2936H189.246V9.21587H196.396V4.19882H176.735V9.21587Z"
          fill={activeLocal === "dark" ? "white" : "#262626"}
        />
        <path
          d="M211.17 3.73586C209.542 3.70298 207.924 3.99584 206.411 4.59737C204.897 5.1989 203.518 6.09706 202.353 7.23947C201.189 8.38188 200.262 9.74569 199.628 11.2514C198.994 12.757 198.665 14.3745 198.659 16.0093C198.656 17.6472 198.979 19.2691 199.61 20.7794C200.241 22.2897 201.167 23.6577 202.333 24.8027C203.499 25.9477 204.882 26.8466 206.4 27.4461C207.918 28.0457 209.539 28.3338 211.17 28.2935C212.794 28.3251 214.408 28.0304 215.917 27.4267C217.426 26.823 218.799 25.9224 219.957 24.778C221.115 23.6335 222.033 22.2683 222.657 20.7625C223.282 19.2566 223.601 17.6406 223.595 16.0093C223.599 14.3789 223.28 12.7641 222.654 11.2595C222.029 9.75494 221.111 8.39097 219.953 7.24769C218.796 6.1044 217.423 5.20483 215.914 4.60177C214.406 3.99871 212.793 3.70431 211.17 3.73586ZM211.17 23.115C210.248 23.1276 209.333 22.9511 208.482 22.5963C207.63 22.2416 206.86 21.716 206.217 21.0519C205.575 20.3877 205.075 19.5988 204.746 18.7337C204.418 17.8685 204.269 16.9451 204.309 16.0201C204.279 15.0979 204.434 14.1791 204.765 13.3186C205.097 12.4581 205.598 11.6737 206.239 11.0122C206.879 10.3508 207.646 9.826 208.494 9.46923C209.341 9.11247 210.251 8.9311 211.17 8.93595C212.092 8.92198 213.007 9.09707 213.859 9.4505C214.711 9.80394 215.482 10.3283 216.125 10.9914C216.768 11.6546 217.27 12.4427 217.6 13.3073C217.929 14.172 218.08 15.0951 218.042 16.0201C218.072 16.9437 217.916 17.8639 217.584 18.7257C217.252 19.5875 216.75 20.3731 216.109 21.0356C215.467 21.698 214.699 22.2236 213.85 22.5809C213.002 22.9382 212.09 23.1199 211.17 23.115Z"
          fill={activeLocal === "dark" ? "white" : "#262626"}
        />
        <path
          d="M237.981 21.1125L231.591 4.19882H225.566L235.161 28.1105H240.618L250.309 4.19882H244.477L237.981 21.1125Z"
          fill={activeLocal === "dark" ? "white" : "#262626"}
        />
        <path
          d="M258.403 4.19882H252.946V28.0998H258.403V4.19882Z"
          fill={activeLocal === "dark" ? "white" : "#262626"}
        />
        <path
          d="M262.638 28.1105H280.701V23.1904H268.094V18.5609H279.565V13.6515H268.094V9.11897H280.701V4.19882H262.638V28.1105Z"
          fill={activeLocal === "dark" ? "white" : "#262626"}
        />
        <path
          d="M283.521 4.19882V9.11897H290.575V28.0136H295.936V9.11897H303V4.19882H283.521Z"
          fill={activeLocal === "dark" ? "white" : "#262626"}
        />
        <path
          d="M90.094 23.481C89.1461 23.5141 88.2016 23.351 87.3192 23.0018C86.4368 22.6526 85.6353 22.1249 84.9647 21.4513C84.294 20.7778 83.7685 19.9729 83.4208 19.0867C83.0731 18.2005 82.9106 17.2519 82.9436 16.3C82.9042 15.3464 83.0622 14.395 83.4076 13.5058C83.7531 12.6167 84.2784 11.8092 84.9504 11.1343C85.6224 10.4594 86.4264 9.93189 87.3118 9.585C88.1971 9.23811 89.1445 9.07941 90.094 9.11894C93.6639 9.11894 95.6364 10.9169 96.3976 13.3716L100.536 9.21583C99.3717 7.50085 97.7958 6.10845 95.9538 5.1675C94.1118 4.22655 92.0631 3.76746 89.9975 3.83273C88.3422 3.79396 86.6959 4.08816 85.1554 4.69799C83.615 5.30782 82.2116 6.22097 81.0279 7.38369C79.8441 8.54641 78.904 9.93521 78.2627 11.4683C77.6214 13.0014 77.2921 14.6479 77.294 16.3107C77.2935 17.9727 77.6239 19.618 78.2658 21.1499C78.9077 22.6817 79.8481 24.0693 81.0317 25.2308C82.2153 26.3924 83.6182 27.3046 85.1579 27.9137C86.6976 28.5229 88.3431 28.8168 89.9975 28.778C96.0224 28.778 101.19 25.096 102.133 19.2392H96.3976C95.6364 21.6938 93.6639 23.4918 90.094 23.4918"
          fill={activeLocal === "dark" ? "white" : "#262626"}
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_34_15094"
          x1="30.3926"
          y1="29.9946"
          x2="69.2431"
          y2="29.9946"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#254393" />
          <stop offset="1" stop-color="#2C9AD5" />
        </linearGradient>
        <clipPath id="clip0_34_15094">
          <rect width="303" height="60" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
