import React from "react";
import { BsFillCalendarEventFill } from "react-icons/bs";
import { FaUserTie } from "react-icons/fa";
import { Link } from "react-router-dom";
import ListBlog from "../../data/BlogData";
import { Pagination, Navigation, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
function Post() {
  function parseDate(dateString) {
    const [day, month, year] = dateString.split("/");
    const date = new Date(year, month - 1, day);
    return date;
  }

  function formatDate(dateString) {
    const date = parseDate(dateString);
    const options = { month: "long", day: "numeric", year: "numeric" };
    const formattedDate = date.toLocaleDateString("en-US", options);
    return ` ${formattedDate}`;
  }

  return (
    <>
      <Swiper
        slidesPerView={3}
        spaceBetween={30}
        loop={true}
        autoplay={true}
        speed={500}
        breakpoints={{
          0: {
            slidesPerView: 1,
            spaceBetween: 30,
          },
          480: {
            slidesPerView: 1,
            spaceBetween: 30,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 30,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
        }}
        className="mySwiper"
        modules={[Autoplay, Navigation]}
      >
        {ListBlog.map((blog) => (
          <SwiperSlide>
            <div
              data-aos="fade-up"
              data-aos-anchor-placement="top-center"
              className="mb-8"
            >
              <Link to={blog.href} target="_blank">
                <div className="rounded-xl hover__image relative overflow-hidden  h-[235px]">
                  <img
                    className="h-full w-full object-cover"
                    src={blog.thumbnail}
                    alt=""
                  />
                  <span className="text absolute top-[45%]">Detail</span>
                </div>
                <h2 className="uppercase hover:text-item py-3 min-h-[70px] mt-2 text-text font-vbold">
                  {blog.title}
                </h2>
              </Link>
              <p className="mb-5 text-text pointer-events-none cursor-auto line-claim font-vlight ">
                {blog.content}
              </p>
              <div className="flex justify-between">
                <span className="flex pb-2 leading-[14px] font-vlight  text-sm text-text">
                  <FaUserTie className="mr-1" />
                  <span className="block">{blog.author}</span>
                </span>
                <span className="font-vlight flex pb-2 leading-[14px] text-sm text-text">
                  <BsFillCalendarEventFill className="mr-1" />
                  <span className="block">{formatDate(blog.createDate)}</span>
                </span>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
}

export default Post;
