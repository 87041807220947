import { useContext } from "react";

import { ThemeContext } from "../App";
import { BsLightbulb, BsLightbulbOff } from "react-icons/bs";
function ThemeToggle() {
  const { theme, setTheme } = useContext(ThemeContext);

  const handleThemeToggle = () => {
    localStorage.setItem("theme", theme);
    setTheme(theme === "light" ? "dark" : "light");
  };

  return (
    <div>
      <button
        onClick={handleThemeToggle}
        className="lg:mt-1 ml-4 md:mr-0 relative z-30 lg:block hidden"
      >
        {localStorage.theme === "dark" ? (
          <BsLightbulb className="h-5 w-5 hover:text-main transition-3" />
        ) : (
          <BsLightbulbOff className="h-5 w-5 text-item hover:text-black transition-3" />
        )}
      </button>
    </div>
  );
}

export default ThemeToggle;
