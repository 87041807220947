import React, { useContext } from "react";
import { FaLinkedinIn, FaTwitter } from "react-icons/fa";
import { Link } from "react-router-dom";
import { ThemeContext } from "../../App";
import Layout from "../../layouts/Layout";
import ScrollToTop from "../More/ScrollToTop";

function Contact() {
  const { activeLocal } = useContext(ThemeContext);
  return (
    <Layout>
      <ScrollToTop />
      <div className="container mx-auto">
        <div className="flex lg:flex-row flex-col-reverse mb-20">
          <div className="flex-1 lg:mt-20 mt-16 ">
            <h2
              data-aos="flip-up"
              data-aos-offset="100"
              data-aos-duration="300"
              className={`  font-vmedium section-title mb-12  ${
                activeLocal === "dark" ? " text-dark" : "text-item"
              }`}
            >
              Contact
            </h2>
            <p
              data-aos="fade-right"
              data-aos-offset="300"
              data-aos-duration="300"
              className="lg:w-4/6 w-full flex-1 font-regular relative text-base lg:text-xl text-text"
            >
              CryptoViet Ventures is one of the pioneers in incubating and
              investing in projects specializing in blockchain. CryptoViet
              Ventures is an investment branch of CryptoViet Info firm.
            </p>
          </div>
        </div>

        <div className="grid grid-flow-row lg:grid-cols-2 gap-y-10 gap-x-32 text-text">
          <div className="">
            <div className="mb-6">
              <h2
                className={`mb-2 ${
                  activeLocal === "dark" ? " text-dark" : "text-item"
                } font-vmedium text-2xl lg:text-3xl`}
              >
                Email
              </h2>
              <Link blank href="ventures@cryptoviet.com">
                contact@cryptoviet.ventures
              </Link>
            </div>

            <div>
              <h2
                className={`mb-2 ${
                  activeLocal === "dark" ? " text-dark" : "text-item"
                } font-vmedium text-2xl lg:text-3xl`}
              >
                Location
              </h2>
              <p>INTERNATIONAL PLAZA, 10 ANSON ROAD, SINGAPORE</p>
            </div>
          </div>
          <div className="grid lg:grid-cols-3">
            <div className=" col-span-1">
              <div>
                <h2
                  className={`mb-2 ${
                    activeLocal === "dark" ? " text-dark" : "text-item"
                  } font-vmedium text-2xl lg:text-3xl`}
                >
                  Social
                </h2>
                <p>You can join with us</p>
                <div className="flex justify-between w-2/4 text-2xl mt-5  ">
                  <a
                    href="https://twitter.com/CryptoVietInfo"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FaTwitter
                      className={` transition-all hover:text-[#1DA1F2] ${
                        activeLocal === "dark" ? "" : "text-[#828282]"
                      }`}
                    />
                  </a>
                  <a href="http://fb" target="_blank" rel="noreferrer">
                    <FaLinkedinIn
                      className={` transition-all hover:text-[#1DA1F2] ${
                        activeLocal === "dark" ? "" : "text-[#828282]"
                      }`}
                    />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-span-2">
              <link rel="preload" as="fetch" href="video/banner4.webm" />
              <video
                className="transition-none lg:block  hidden -mt-32"
                muted
                loop
                autoPlay
                playsInline
                width="100%"
                height="100%"
                type="video/mp4"
                preload="auto"
              >
                <source
                  src="video/banner4.webm"
                  type='video/webm; codecs="vp8, vorbis"'
                ></source>
              </video>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Contact;
