import React, { useContext } from "react";
import { BsFillCalendarEventFill } from "react-icons/bs";
import { FaUserTie } from "react-icons/fa";
import { Link } from "react-router-dom";
import { ThemeContext } from "../../App";
import Layout from "../../layouts/Layout";
import ScrollToTop from "../More/ScrollToTop";
import ListBlog from "../../data/BlogData";

function News() {
  const { activeLocal } = useContext(ThemeContext);

  function parseDate(dateString) {
    const [day, month, year] = dateString.split("/");
    const date = new Date(year, month - 1, day);
    return date;
  }

  function formatDate(dateString) {
    const date = parseDate(dateString);
    const options = { month: "long", day: "numeric", year: "numeric" };
    const formattedDate = date.toLocaleDateString("en-US", options);
    return ` ${formattedDate}`;
  }

  return (
    <Layout>
      <ScrollToTop />
      <div className="container mx-auto mt-8 lg:mt-0">
        <div className="flex lg:flex-row flex-col-reverse mb-16">
          <div className="flex-1 text-center">
            <h2
              data-aos="flip-up"
              data-aos-offset="200"
              data-aos-duration="500"
              className={`text-item  font-vbold section-title mb-12 text-center ${
                activeLocal === "dark" ? "text-dark" : "text-item"
              }`}
            >
              Latest News
            </h2>
            <p
              data-aos="fade-right"
              data-aos-offset="200"
              data-aos-duration="500"
              className="lg:w-4/6 w-full mx-auto flex-1 -mt-6 font-vlight relative text-base lg:text-xl text-text"
            >
              Quickly update information about projects invested by CryptoViet
              Ventures
            </p>
          </div>
        </div>
        <div className="grid lg:grid-cols-3 md:grid:cols-2 grid:cols-1 gap-x-7">
          {ListBlog.map((blog) => (
            <div
              data-aos="fade-up"
              data-aos-anchor-placement="top"
              className="mb-8 relative z-10"
            >
              <Link to={blog.href} target="_blank">
                <div className="rounded-xl hover__image relative overflow-hidden   h-[235px]">
                  <img
                    className="h-full w-full object-cover"
                    src={blog.thumbnail}
                    alt=""
                  />
                  <span className="text absolute top-[45%]">Detail</span>
                </div>
                <h2 className="uppercase hover:text-item py-3 mt-2 text-text font-vbold">
                  {blog.title}
                </h2>
              </Link>
              <p className="mb-4 text-text pointer-events-none cursor-auto ">
                {blog.content}
              </p>
              <div className="flex justify-between">
                <span className="flex pb-2 leading-[14px] font-vlight  text-sm text-text">
                  <FaUserTie className="mr-1" />
                  <span className="block">{blog.author}</span>
                </span>
                <span className="font-vlight flex pb-2 leading-[14px] text-sm text-text">
                  <BsFillCalendarEventFill className="mr-1" />
                  <span className="block">{formatDate(blog.createDate)}</span>
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
}

export default News;
