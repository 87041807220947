import React from "react";
import Insights from "../components/Home/Insights";
import Invest from "../components/Home/Invest";
import Portfolio from "../components/Home/Portfolio";
import ScrollToTop from "../components/More/ScrollToTop";
import Layout from "../layouts/Layout";

function HomeScreen() {
  return (
    <Layout>
      <ScrollToTop />
      <Invest />
      <Portfolio />
      <Insights />
    </Layout>
  );
}

export default HomeScreen;
