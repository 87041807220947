import React, { useContext, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { ThemeContext } from "../../App";
import ThemeToggle from "../../Themes/Themes";
import { LogoHeader } from "../../components/Logo";
function NavBar() {
  const { activeLocal } = useContext(ThemeContext);
  const [menuOpen, setMenuOpen] = useState(false);

  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(!isOpen);
    setMenuOpen(!menuOpen);
  };

  let flag = false;
  const pathName = window.location.pathname;

  flag = ["/carrers", "/blogs", "/contact"].includes(pathName) ? true : false;

  return (
    <>
      <div className="container flex lg:flex-row md:flex-row md:justify-between lg:justify-between mx-auto lg:py-10 py-3 px-2 ">
        <div className="col-span-1 z-10">
          <Link to="/" className="lg:block hidden">
            <LogoHeader activeLocal={activeLocal} />
          </Link>
        </div>
        <div className="blur__bg-head hidden  lg:block "></div>

        {/* Menu */}
        <nav>
          <ul
            className={`menu border-b-1 ${
              activeLocal === "dark" ? " border-white" : "border-item"
            } font-medium text-sm hidden gap-x-[95px] 0 justify-between lg:flex xl:justify-end items-center`}
          >
            <NavLink
              to="/"
              className={({ isActive }) =>
                isActive
                  ? "current h-8 list-none cursor-pointer text-base !text-[#2a82c3] font-vmedium home"
                  : "h-8 list-none cursor-pointer text-base text-text font-vmedium home"
              }
              spy={true}
              smooth={true}
            >
              Invest
            </NavLink>
            <NavLink
              to="/portfolio"
              className={({ isActive }) =>
                isActive
                  ? "current h-8 list-none cursor-pointer text-base !text-[#2a82c3] font-vmedium portfolio2"
                  : "h-8 list-none cursor-pointer text-base text-text font-vmedium portfolio2"
              }
            >
              Portfolio
            </NavLink>
            <div
              className={`group ${flag && "current"} ${
                activeLocal === "dark" ? " gr__pc-dark" : "gr__pc"
              }  relative insight  h-8 list-none cursor-pointer text-base text-text font-vmedium`}
            >
              <NavLink className={`${flag && "!text-[#2a82c3]"}`} to="/">
                Insights
              </NavLink>
              <div
                className="text-center absolute flex flex-col py-1 px-4 mt-[9px]
               group-hover:translate-y-1 group-hover:visible invisible
               "
              >
                {/* <Link
                  to="/carrers"
                  className="block  py-1 font-medium hover:text-item text-text md:mx-2"
                >
                  Careers
                </Link> */}

                <Link
                  to="/blogs"
                  className="block  py-1 font-medium hover:text-item text-text md:mx-2"
                >
                  Blogs
                </Link>
                <Link
                  to="/contact"
                  className="block  py-1 font-medium hover:text-item text-text md:mx-2"
                >
                  Contact
                </Link>
              </div>
            </div>
            <NavLink
              to="/login"
              className={({ isActive }) =>
                isActive
                  ? "current h-8 list-none cursor-pointer text-base !text-[#2a82c3] font-vmedium login"
                  : "h-8 list-none cursor-pointer text-base text-text font-vmedium login"
              }
            >
              Login
            </NavLink>
            <span
              className={`dot border-1 ${
                activeLocal === "dark"
                  ? " border-white bg-[#092145]"
                  : "border-item bg-white"
              }`}
            ></span>
          </ul>
        </nav>

        <div className="lg:hidden cursor-pointer z-50 fixed">
          <div
            id="nav-icon3"
            className={`mt-1 z-50 ${isOpen ? "open" : ""}`}
            onClick={handleClick}
          >
            <span
              className={`${isOpen && "!bg-white"} ${
                activeLocal === "dark" ? "bg-white" : "bg-item"
              } `}
            ></span>
            <span
              className={`${isOpen && "!bg-white"} ${
                activeLocal === "dark" ? "bg-white" : "bg-item"
              }`}
            ></span>
            <span
              className={`${isOpen && "!bg-white"} ${
                activeLocal === "dark" ? "bg-white" : "bg-item"
              }`}
            ></span>
          </div>
          {/* Menu Mobile */}

          <div id="menu-show" className="uk-menu1 w-full flex ">
            <div
              style={{
                transform: menuOpen ? "translateX(0px) " : "translateX(-600px)",
              }}
              className="w-[100%] z-15 uk-menu2 bg-transparent opacity-90  transition-4
              
            "
            >
              <div className="w-[50%] relative  h-full z-1 bg-item"></div>
              <div className="flex justify-items-start items-center ">
                <div className="w-4/5 sm:w-3/4 md:w-800px absolute mt-16 top-0 z-50">
                  <ul className="list-none font-semibold w-[50%] h-full bg-item text-2xl p-4 ">
                    <li
                      className={`${
                        menuOpen ? "fadeInRight" : ""
                      } text-xl close  z-50 animation__delay-3s p-4 mobile-menu `}
                    >
                      <Link to="/" className="text-white">
                        Invest
                      </Link>
                    </li>
                    <li
                      className={`${
                        menuOpen ? "fadeInRight" : ""
                      } text-xl close  z-50 animation__delay-4s p-4 mobile-menu `}
                    >
                      <Link to="/portfolio" className="text-white">
                        Portfolio
                      </Link>
                    </li>
                    <li
                      className={`${
                        menuOpen ? "fadeInRight" : ""
                      } text-xl close animation__delay-5s p-4 mobile-menu group hover:mb-[120px] transition-hover relative`}
                    >
                      <Link to="/" className="text-white relative">
                        Insight
                      </Link>
                      <div className="-translate-y-2 invisible flex-col transition-2 opacity-0 z-1 py-1 px-12 group-hover:translate-y-4  group-hover:visible absolute group-hover:opacity-100">
                        <Link
                          to="/carrers"
                          className=" block text-lg  py-1 font-regular hover:text-gray  text-white md:mx-2"
                        >
                          Careers
                        </Link>

                        <Link
                          to="/blogs"
                          className="block text-lg  py-1 font-regular hover:text-gray text-white md:mx-2"
                        >
                          Blogs
                        </Link>
                        <Link
                          to="/contact"
                          className=" block text-lg  py-1 font-regular hover:text-gray text-white md:mx-2"
                        >
                          Contact
                        </Link>
                      </div>
                    </li>
                    <li
                      className={`${
                        menuOpen ? "fadeInRight" : ""
                      } " text-xl close  animation__delay-6s  p-4 mobile-menu "`}
                    >
                      <Link to="/login" className="text-white">
                        Login
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          {/* {End menu Mobile} */}
        </div>
        <ThemeToggle />
      </div>

      <div className="blur__bg lg:block hidden"></div>
      <div className="blur__bg-mobile"></div>
    </>
  );
}

export default NavBar;
