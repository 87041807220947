// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

import { getFirestore } from "@firebase/firestore";
import { getStorage } from "firebase/storage";
import "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDVFGUafHdZpekb4Ajbz7K9b0fFw2nEil8",
  authDomain: "cryptoviet-ventures.firebaseapp.com",
  projectId: "cryptoviet-ventures",
  storageBucket: "cryptoviet-ventures.appspot.com",
  messagingSenderId: "290292625327",
  appId: "1:290292625327:web:7ca21cd362fccddcaec95a",
  measurementId: "G-C8DV6057V9"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

export const db = getFirestore(app);
export const imageDb = getStorage(app)