const ListBlog = [
  {
    id: 1,
    title: "CAPITAL REPORT CRYPTO VENTURES - Q3/2023",
    content:
      "According to the quarterly report by CertiK, a blockchain security firm, the third quarter of 2023 has been the most financially devastating period of the year, as nearly $700 million worth of digital assets were lost due to different security incidents, surpassing first-quarter losses of $320 million and second-quarter losses of $313 million. Mixin Network represents the largest security loss case, with $200 million worth of assets drained from their mainnet.",
    thumbnail:
      "https://cryptoviet.info/_next/image?url=https%3A%2F%2Fadmin.cryptoviet.info%2Fuploads%2FThumbnail_Q3_2023_ea4374fd98.png&w=1200&q=100",
    href: "https://cryptoviet.info/capital-report-crypto-ventures-q3-2023",
    author: "Thanh Dat",
    createDate: "18/10/2023",
  },
  {
    id: 1,
    title: "LayerZero: Was It Just Hype?",
    content:
      "LayerZero is an interoperability protocol that facilitates communication and data transfer between different blockchain networks. It operates through on-chain endpoints with two essential entities, the Oracle and the Relayer, to facilitate the exchange of messages between blockchains.",
    thumbnail:
      "https://gaia.hiro.so/hub/1DeR7NULC7MtxA8as66ebWdfmp44vxnYgA/photos/e6hqE9bSRF-Pc55hercla/OVSynRcVp0ofWVJd_7Bp9-LayerZero%20article_The%20VC%20Whisperer.png",
    href: "https://www.thevcwhisperer.io/article/layerzero-was-it-just-hype",
    author: "Thevcwhisperer",
    createDate: "04/10/2023",
  },
  {
    id: 1,
    title: "Capital Report Crypto Venture - Q2/2023",
    content:
      "The second quarter of 2023 witnessed significant volatility in the cryptocurrency industry, bringing both opportunities and risks for investors and companies operating in this field. In the Crypto VC sector, which involves cryptocurrency investment activities, Q2/2023 experienced a series of notable events, both positive and negative. Some of the notable events are as follows:",
    thumbnail:
      "https://cryptoviet.info/_next/image?url=https%3A%2F%2Fadmin.cryptoviet.info%2Fuploads%2FThumbnail_E_e129513158.jpg&w=1200&q=100",
    href: "https://cryptoviet.info/crypto-and-blockchain-venture-capital-report-q2-2023",
    author: "Thanh Dat",
    createDate: "10/07/2023",
  },
  {
    id: 2,
    title:
      "Shardeum Completes $5.4 Million Strategic Raise from Amber Group, Galxe, J17 Capital",
    content:
      "Shardeum Completes $5.4 Million Strategic Raise from Amber Group, Galxe, J17 Capital, TRGC and Others to Grow the Ecosystem in the Lead-up to Mainnet Launch",
    thumbnail:
      "https://shardeum.org/blog/wp-content/uploads/2023/07/F0hWT-1aMAkZEtb.jpg",
    href: "https://shardeum.org/blog/shardeum-completes-5-4-million-strategic-raise/",
    author: "Priyanka Sharma",
    createDate: "08/07/2023",
  },
  {
    id: 4,
    title: "Crypto Protocol LayerZero Raises $120M at $3B Valuation",
    content:
      "Consensus 2023 Logo Join the most important conversation in crypto and Web3 taking place in Austin, Texas, April 26-28. Secure You Seat Blockchain infrastructure provider LayerZero Labs has raised $120 million in a Series B funding round at a value...",
    thumbnail:
      "https://www.coindesk.com/resizer/X4_fcy6e1nn0rqbXWaIp7-vxD_s=/1200x628/center/middle/cloudfront-us-east-1.images.arcpublishing.com/coindesk/KFRAZPPIINHA3LHD2IB62WQH7Y.jpg",
    href: "https://www.coindesk.com/business/2023/04/04/crypto-protocol-layerzero-raises-120m-series-b-at-3b-valuation/",
    author: "Brandy Betz",
    createDate: "05/04/2023",
  },

  {
    id: 5,
    title:
      "CryptoViet Labs Announces Partnership with IMBA Studio & Megala Ventures",
    content:
      "CryptoViet Labs, a leading blockchain company, today announced that it has partnered with IMBA Studio to develop and publish an NFT game project called “Heroes & Empires which is co-incubated by Megala Ventures.",

    thumbnail: "/images/post_cvlab.jpeg",
    href: "https://www.coinspeaker.com/cryptoviet-labs-announces-partnership-with-imba-studio-megala-ventures/",
    author: "CryptoViet Labs",
    createDate: "17/08/2021",
  },

  {
    id: 6,
    title:
      "Blockchain based play-to-earn game ‘Heroes & Empires’ raised $1.5 M",
    content:
      "  The Strategic round of Heroes & Empires has closed with $1.5 million raised through strategic fundraising.Games can now ‘play-to-earn’ on the blockchain, and Heroes & Empires is the game that you don’t want to miss.",
    thumbnail:
      "https://images.cointelegraph.com/cdn-cgi/image/format=auto,onerror=redirect,quality=90,width=717/https://s3.cointelegraph.com/storage/uploads/view/d94d964d31b6893dc6d61701ec563268.jpg",
    href: "https://cointelegraph.com/press-releases/blockchain-based-play-to-earn-game-heroes-empires-raised-15-m/",
    author: "Cointelegraph",
    createDate: "19/08/2021",
  },
];

export default ListBlog;
