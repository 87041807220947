import React, { createContext, useEffect, useState } from "react";
import HomeScreen from "./screens/HomeScreen";

import Aos from "aos";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Conditions from "./components/Insight/Conditions";
import Contact from "./components/Insight/Contact";
import Login from "./components/Insight/Login";
import News from "./components/Insight/News";
import Policy from "./components/Insight/Policy";
import Preload from "./components/More/Preload";
import PreloadOpacity from "./components/More/PreloadOpacity";
import PortfolioScreen from "./screens/PortfolioScreen";
export const ThemeContext = createContext({});

function App() {
  const [theme, setTheme] = useState("dark");

  useEffect(() => {
    Aos.init({
      duration: 1000,
      delay: 200,
    });
  });

  const router = createBrowserRouter([
    {
      path: "/",
      element: <HomeScreen />,
      children: [],
    },
    {
      path: "/portfolio",
      element: <PortfolioScreen />,
      children: [],
    },
    {
      path: "/blogs",
      element: <News />,
      children: [],
    },
    // {
    //   path: "/carrers",
    //   element: <Carrers />,
    //   children: [],
    // },
    {
      path: "/contact",
      element: <Contact />,
      children: [],
    },

    {
      path: "/login",
      element: <Login />,
      children: [],
    },

    {
      path: "/conditions",
      element: <Conditions />,
      children: [],
    },

    {
      path: "/policy",
      element: <Policy />,
      children: [],
    },
  ]);

  const activeLocal = localStorage.theme;
  return (
    <>
      <ThemeContext.Provider value={{ theme, setTheme, activeLocal }}>
        <Preload>
          <PreloadOpacity />
          <RouterProvider router={router} />
        </Preload>
      </ThemeContext.Provider>
    </>
  );
}

export default App;
