import React from "react";
import { FaLinkedinIn, FaTwitter } from "react-icons/fa";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <>
      <div className="bg-main py-5 mt-28 relative z-30">
        <div className="container items-center mx-auto flex flex-col lg:flex-row justify-between">
          <h1 className="text-white text-[16px] mt-2 lg:mt-0 text-center font-vbold lg:text-2xl">
            Subscribe To Our Newsletter
          </h1>

          <button
            className="
            border-2 btn-hover bg-white uppercase lg:w-auto md:w-1/3 font-vbold text-main  py-4 w-full lg:px-12 lg:mt-0 mt-8 lg:mb-0 mb-2 lg:mx-0 mx-auto  text-xs rounded-lg lg:rounded"
          >
            <span>Subscribe</span>
          </button>
        </div>
      </div>

      {/* Footer */}
      <div className="bg-black relative z-30">
        <div className="container mx-auto">
          <div className="flex justify-between py-8">
            <div className="grid lg:grid-cols-4 md:grid-cols-4 grid-cols-2  text-white ">
              <div className="lg:order-none md:order-none order-1">
                <span className="block font-vmedium mb-1 md-mb-2 lg:mb-2 text-lg mt-6 md:mt-0 lg:mt-0 text-main ">
                  Service
                </span>
                <div className="flex-col flex">
                  <Link
                    className="hover:text-main transition-all leading-6  text-sm font-vlight"
                    to="/"
                  >
                    Investor
                  </Link>
                  <Link
                    className="hover:text-main transition-all leading-6  text-sm font-vlight"
                    to="/portfolio"
                  >
                    Portfolio
                  </Link>
                  {/* <Link
                    className="hover:text-main transition-all leading-6  text-sm font-vlight "
                    to="/careers"
                  >
                    Careers
                  </Link> */}
                  <Link
                    target="_blank"
                    className="hover:text-main transition-all leading-6  text-sm font-vlight "
                    to="https://cryptoviet.info/analytics/kien-thuc/"
                  >
                    Blogs
                  </Link>

                  <Link
                    className="hover:text-main transition-all leading-6  text-sm font-vlight "
                    to="/contact"
                  >
                    Contact
                  </Link>
                </div>
              </div>

              <div className="lg:order-none md:order-none order-3">
                <span className="block font-vmedium mb-1 lg:mb-2 md:mb-2 text-lg mt-6 md:mt-0 lg:mt-0 text-main ">
                  Legal
                </span>
                <div className="flex-col flex">
                  <Link
                    className="hover:text-main transition-all leading-6  text-sm font-vlight"
                    to="/policy"
                  >
                    Privacy Policy
                  </Link>
                  <Link
                    className="hover:text-main transition-all leading-6  text-sm font-vlight "
                    to="/conditions"
                  >
                    Term & Conditions
                  </Link>

                  <Link
                    className="hover:text-main transition-all leading-6  text-sm font-vlight "
                    to="https://drive.google.com/drive/folders/1YCFveoDK68H1Zv1ypuDPWvIcySPCUSVZ"
                    target="_blank"
                  >
                    Media Kit
                  </Link>
                </div>
              </div>

              <div className="lg:order-none md:order-none order-4">
                <span className="block font-vmedium mb-1 lg:mb-2 md:mb-2 text-lg mt-6 md:mt-0 lg:mt-0 text-main ">
                  Ecosystem
                </span>
                <div className="flex-col flex">
                  <Link
                    className="hover:text-main transition-all leading-6  text-sm font-vlight"
                    to="https://cryptoviet.info/"
                  >
                    CryptoViet Info
                  </Link>
                  <Link
                    className="hover:text-main transition-all leading-6  text-sm font-vlight "
                    to="https://cryptoviet.ventures/"
                  >
                    CryptoViet Ventures
                  </Link>

                  <Link
                    className="hover:text-main transition-all leading-6  text-sm font-vlight "
                    to="https://grindy.io/"
                  >
                    Grindy Technologies
                  </Link>
                </div>
              </div>

              <div className="lg:order-none md:order-none order-2 lg:mt-0 md:mt-0 mt-7">
                <div className="right-0">
                  <img src="/logo_footer.svg" className="" alt="" />
                  {/* <img src="/logo_footer-mb.svg" className="lg:hidden" alt="" /> */}
                  <div className="flex mt-5">
                    <Link
                      target="_blank"
                      className="hover:text-main text-white transition-all leading-6  text-2xl mr-4 font-vlight"
                      to="https://twitter.com/CryptoVietInfo"
                    >
                      <FaTwitter className="mb-2 " />
                    </Link>
                    <Link
                      target="_blank"
                      className="hover:text-main text-white transition-all leading-6  text-2xl font-vlight "
                      to="https://www.linkedin.com/company/cryptoviet-ventures/"
                    >
                      <FaLinkedinIn />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="pb-8 flex justify-between lg:flex-row md:flex-row flex-col-reverse">
            <p className="text-main font-vlight text-sm">
              CryptoViet Ventures @ 2021. All Rights Reserved
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
