import React, { useContext, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import emailjs from "@emailjs/browser";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ThemeContext } from "../../../App";
import { MdChangeCircle } from "react-icons/md";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { imageDb } from "../../../utils/fireBaseConfig";

const SignupSchema = yup.object().shape({
  project: yup.string().required(),
  email: yup.string().required(),
  stage: yup.string().required(),
  background: yup.string().required(),
  funding: yup.string().required(),
  website: yup.string().required(),
});

function FormFireBase() {
  const form = useRef();
  const [sendMail, setSendMail] = useState(false);
  const [change, setChange] = useState(false);
  const { activeLocal } = useContext(ThemeContext);

  const [imageUpload, setImageUpload] = useState(null);

  const classInput = `bg-transparent text-[#5d5555] w-full text-sm border-1 border-gray-400 outline-none rounded-md py-2 px-3 ${
    activeLocal === "dark"
      ? "placeholder:text-[#efefef] text-main"
      : "placeholder:text-[#5d5555] text-item"
  }`;

  //   const documentInput = document.querySelector(".document_attach input");

  //   const docSet = documentInput?.setAttribute("type", "file");
  //   console.log(documentInput, docSet);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(SignupSchema),
  });

  const uploadFile = () => {
    try {
      if (imageUpload) {
        const imageRef = ref(imageDb, `images/${imageUpload.name}`);

        uploadBytes(imageRef, imageUpload).then((snapshot) => {
          getDownloadURL(snapshot.ref).then((url) => {
            if (form.current) {
              const documentInput = form.current.querySelector(
                ".document_attach input"
              );
              if (documentInput) {
                documentInput.setAttribute("type", "text");
                documentInput.setAttribute("value", url);

                emailjs.sendForm(
                  "service_srbgngk",
                  "template_zfzmbz3",
                  form.current,
                  "blZ8c4Wa0QxD5hx7c"
                );
                setSendMail(true);
              }
            }
          });
        });
      } else {
        emailjs.sendForm(
          "service_srbgngk",
          "template_zfzmbz3",
          form.current,
          "blZ8c4Wa0QxD5hx7c"
        );
        setSendMail(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div>
        {!sendMail ? (
          <div>
            <div className="mb-6 mt-16 lg:mt-[140px]">
              <h2 className="text-text items-center lg:font-vbold font-vmedium text-xl mb-3 lg:text-xl">
                INVESTOR INQUIRY
              </h2>
              <p className="font-vlight text-text">
                If your project/startup is looking to launch successfully.
                Contact us, we'll cut all the hype for you!
              </p>
            </div>
            <form
              enctype="multipart/form-data"
              method="post"
              ref={form}
              onSubmit={handleSubmit(uploadFile)}
              className="relative z-30"
            >
              <div>
                <input
                  name="project"
                  type="text"
                  placeholder="Project Name"
                  className={`${
                    errors.project
                      ? "border-red-600 bg-transparent w-full text-sm border-1  placeholder:text-red-500 font-light text-item outline-none rounded-md py-2 px-3"
                      : classInput
                  } `}
                  {...register("project")}
                />
              </div>
              <div className="grid lg:grid-cols-2 gap-7 mt-7">
                <div>
                  <input
                    name="website"
                    type="text"
                    placeholder="Website"
                    className={`${
                      errors.website
                        ? "border-red-600 bg-transparent w-full text-sm border-1  placeholder:text-red-500 font-light text-item outline-none rounded-md py-2 px-3"
                        : classInput
                    } `}
                    {...register("website")}
                  />
                </div>

                <div>
                  {" "}
                  <input
                    name="email"
                    type="email"
                    placeholder="Email"
                    className={`${
                      errors.email
                        ? "border-red-600 bg-transparent w-full text-sm border-1  placeholder:text-red-500 font-light text-red-600 outline-none rounded-md py-2 px-3"
                        : classInput
                    } `}
                    {...register("email")}
                  />
                </div>
              </div>
              <div className="mt-7">
                <div>
                  <input
                    name="stage"
                    type="text"
                    title="What is the current funding round of your project (Pre-Seed, Seed, Private , Series A, ...)"
                    placeholder="What is the current funding round of your project (Pre-Seed, Seed, Private , Series A, ...)"
                    className={`${
                      errors.stage
                        ? "border-red-600 bg-transparent w-full text-sm border-1  placeholder:text-red-500 font-light text-red-600 outline-none rounded-md py-2 px-3"
                        : classInput
                    } `}
                    {...register("stage")}
                  />
                </div>
              </div>
              <div className="mt-7">
                <div>
                  <input
                    name="funding"
                    type="text"
                    placeholder="What are previous funding rounds that you raised? Who are your backers/investors?"
                    className={`${
                      errors.funding
                        ? "border-red-600 bg-transparent w-full text-sm border-1  placeholder:text-red-500 font-light text-red-600 outline-none rounded-md py-2 px-3"
                        : classInput
                    } `}
                    {...register("funding")}
                  />
                </div>
              </div>
              <div className="mt-7">
                <div>
                  <input
                    name="background"
                    type="text"
                    placeholder="Your founding team’s background. Twitter or LinkedIn may be helpful."
                    className={`${
                      errors.background
                        ? "border-red-600 bg-transparent w-full text-sm border-1  placeholder:text-red-500 font-light text-red-600 outline-none rounded-md py-2 px-3"
                        : classInput
                    } `}
                    {...register("background")}
                  />
                </div>
              </div>

              <div className="mt-7 document_attach relative overflow-hidden rounded-md flex items-center">
                <input
                  name="deck"
                  type={`${change ? "file" : "text"}`}
                  placeholder="Pitch deck or any documents."
                  onChange={(event) => {
                    setImageUpload(event.target.files[0]);
                  }}
                  title="Pitch deck or any documents."
                  className={`relative overflow-hidden h-[44.6px] ${
                    errors.deck
                      ? "border-red-600 bg-transparent w-full text-sm border-1 rounded-md  placeholder:text-red-500 font-light text-item outline-none py-2 px-3"
                      : classInput
                  } `}
                  //   {...register("deck")}
                />

                <span className="absolute block h-full right-0 top-0 pb-2 pr-2 ">
                  <MdChangeCircle
                    onClick={() => setChange(!change)}
                    className={`${
                      change === true ? "rotate-90" : "rotate-180"
                    } transition-all mt-2.5 text-2xl hover:scale-110 cursor-pointer`}
                  />
                </span>
              </div>
              <div className="mt-7">
                <div>
                  {" "}
                  <textarea
                    rows={3}
                    name="additional"
                    type="text"
                    placeholder="Additional Question"
                    className={`"bg-transparent text-sm border-1 border-gray-400  outline-none w-full rounded-md py-2 px-3 ${
                      activeLocal === "dark"
                        ? "placeholder:text-[#efefef] bg-transparent text-main "
                        : "placeholder:text-[#5d5555]"
                    } "`}
                  />
                </div>
              </div>

              <div className="mt-7">
                <div>
                  <input
                    name="submit"
                    type="submit"
                    className={` px-14 py-3 uppercase  border-2  transition-all hover:bg-item cursor-pointer font-vbold text-white bg-main float-right text-sm rounded-md  ${
                      activeLocal === "dark" ? "border-item" : "border-white"
                    } `}
                    value="Submit"
                  />
                </div>
              </div>
            </form>

            {/* <div className="App">
              <input
                type="file"
                onChange={(event) => {
                  setImageUpload(event.target.files[0]);
                }}
              />
              <button onClick={uploadFile}>Upload</button>
            </div> */}
          </div>
        ) : (
          <div>
            <div className={`mb-7 lg:mt-24 mt-10 `}>
              <h2
                className={`${
                  activeLocal === "dark" ? "text-dark" : "text-item"
                } section-title font-vmedium`}
              >
                You’re all set
              </h2>
              <p
                className={`${
                  activeLocal === "dark" ? "text-white" : "text-[#262626]"
                } text-base font-vlight lg:text-xl`}
              >
                Thank you for submitting the form. You should receive an email
                shortly containing more information about our fund offerings.
                Please reach out to contact@cryptoviet.ventures if you have any
                questions or would like to be sent subscription documents.
              </p>
            </div>
            <video
              className="transition-none mx-auto"
              muted
              loop
              autoPlay
              playsInline
              width="60%"
              height="100%"
            >
              <source src="video/check.webm" type="video/webm"></source>
            </video>
          </div>
        )}
      </div>
    </>
  );
}

export default FormFireBase;
