const ListPortfolio = [
  {
    id: 1,
    name: "Ancient8",
    content:
      "The infrastructure for Web3 Gaming, help players and communities worldwide build and play in the Metaverse.",
    logo: "images/portfolio/acident.svg",
    href: "https://ancient8.gg/",
    tweet: "https://twitter.com/Ancient8_gg",
    linkedin: "https://www.linkedin.com/company/ancient8/",
  },

  {
    id: 2,
    name: "Supra Oracles",
    content:
      "A powerful blockchain oracle that helps businesses bridge real-world data to both public and private chains enabling interoperable smart contracts.",
    logo: "images/portfolio/SO.svg",
    href: "https://supraoracles.com/blastoff?ref=a91d7-128889&gclid=Cj0KCQjw8e-gBhD0ARIsAJiDsaWZA8UjeD_z_gV3CYq9axnf0_WXuSGP1twAZB8qgIJDspgwrzapst0aAtv4EALw_wcB",
    tweet: "https://twitter.com/SupraOracles",
    linkedin: "https://www.linkedin.com/company/supraoracles/",
  },

  {
    id: 3,
    name: "Emboldn",
    content:
      "Nirvana Interactive’s flagship IP ‘Emboldn’, a gameplay-first experience, and a well-balanced game economy is yet to be seen.",
    logo: "images/portfolio/embodln.svg",
    href: "https://www.emboldn.io/",
    tweet: "https://twitter.com/emboldn",
    linkedin: "https://www.linkedin.com/company/nirvana-interactive/",
  },

  {
    id: 4,
    name: "Suquery Network",
    content:
      "An Open, Flexible, Fast and Universal data indexing framework for web3, help developers create the decentralised products of the future.",
    logo: "images/portfolio/SB.svg",
    href: "https://subquery.network/",
    tweet: "https://twitter.com/SubQueryNetwork",
    linkedin: "https://www.linkedin.com/company/subquery/",
  },

  {
    id: 5,
    name: "Gafi Network",
    content:
      "Built on top-class technologies, architectures, and mechanisms to provide exclusive solutions to solve the problems of Blockchain Gaming.",
    logo: "images/portfolio/GAFI.svg",
    href: "https://gafi.network/",
    tweet: "https://twitter.com/GafiNetwork",
    linkedin: "https://www.linkedin.com/company/grindytech/about/",
  },
  {
    id: 5,
    name: "Lumio",
    content:
      "A key DeFi hub on Cardano backed by EMURGO & Adaverse. Stake, Trade and Earn Crypto with Lumino on Cardano.",
    logo: "images/portfolio/lumino.svg",
    href: "https://www.luminodefi.io/",
    tweet: "https://x.com/LuminoFi",
    linkedin: "https://www.luminodefi.io/",
  },
  {
    id: 6,
    name: "Heroes and Empires",
    content:
      "A Skill-2-Earn strategy game that combines the distinctive elements of Idle RPG and the tactics of auto chess, connected with blockchain. ",
    logo: "images/portfolio/HE.svg",
    href: "https://heroesempires.com/",
    tweet: " https://twitter.com/HeroesEmpires",
    linkedin: "https://www.linkedin.com/company/heroes-empires/",
  },

  {
    id: 7,
    name: "KILT Protocol",
    content:
      "An open-source blockchain protocol for creating Web 3.0 credentials that are verifiable, revocable, and anonymous.",
    logo: "images/portfolio/kilt.svg",
    href: "https://www.kilt.io/",
    tweet: "https://twitter.com/Kiltprotocol",
    linkedin: "https://www.linkedin.com/company/kilt-protocol/",
  },

  {
    id: 8,
    name: "Star Atlas",
    content:
      "A blockchain-based, space-themed, grand strategy video game built on the Solana. It is a massive multiplayer metaverse set in the distant future, in the year 2620.",
    logo: "images/portfolio/SA.svg",
    href: "https://staratlas.com/",
    tweet: "https://twitter.com/staratlas",
    linkedin: "https://www.linkedin.com/company/star-atlas/",
  },

  {
    id: 8,
    name: "Scallop",
    content:
      "Scallop is the Next Generation Money Market which emphasizes institutional-grade quality, enhanced composability, and robust security",
    logo: "images/portfolio/scallop.svg",
    href: "https://www.scallop.io/",
    tweet: "https://twitter.com/Scallop_io",
    linkedin: "#",
  },
  {
    id: 8,
    name: "Your Protocol",
    content:
      "In a world filled with generic messages, personalization is the key to standing out and creating a dynamic, engaging experience tailored to each individual's unique needs and interests.",
    logo: "images/portfolio/your.svg",
    href: "https://your.io/",
    tweet: "https://twitter.com/yourtokens",
    linkedin: "#",
  },

  {
    id: 9,
    name: "Casper Network",
    content:
      "A decentralized, layer -1 PoS blockchain optimized for enterprise and developer adoption. It is the first live blockchain built off the Correct-by-Construction (CBC) Casper specification. ",
    logo: "images/portfolio/casper.svg",
    href: "https://casper.network/en-us",
    tweet: "https://twitter.com/Casper_Network",
    linkedin: "https://www.linkedin.com/company/casper-association/",
  },

  {
    id: 10,
    name: "Shardeum",
    content:
      "An EVM-based, linearly scalable smart contract platform that provides low gas fees forever while maintaining true decentralization and solid security through dynamic state sharding.",
    logo: "images/portfolio/shardeum.svg",
    href: "https://shardeum.org/",
    tweet: "https://twitter.com/shardeum",
    linkedin: " https://www.linkedin.com/company/shardeum/",
  },

  {
    id: 11,
    name: "LayerZero",
    content:
      "An omnichain interoperability protocol designed for lightweight message passing across chains, provides authentic and guaranteed message delivery with configurable trustlessness.",
    logo: "images/portfolio/layer0.svg",
    href: "https://layerzero.network/",
    tweet: "https://twitter.com/LayerZero_Labs",
    linkedin: "https://www.linkedin.com/company/layerzerolabs/",
  },

  {
    id: 12,
    name: "Quai Network",
    content:
      "The only decentralized cryptocurrency with the capacity to scale to all global commerce. Quai is a merge-mined network of blockchains.",
    logo: "images/portfolio/quai.svg",
    href: "https://qu.ai/",
    tweet: "https://twitter.com/QuaiNetwork",
    linkedin: "https://www.linkedin.com/company/quai-network/",
  },
  {
    id: 13,
    name: "Another 1",
    content:
      "Empowers Consumers, Builders, Creators and Brands. The Ultimate Phygital Fashion Community. Infrastructure for Web3 Fashion.",
    logo: "images/portfolio/pmd.svg",
    href: "https://www.another-1.io/",
    tweet: "https://twitter.com/another1_io",
    linkedin: "",
  },
  {
    id: 14,
    name: "Unibit",
    content:
      "Unlock the power of cross-chain DeFi innovation with BRC-20 platform: BRC-20 Bridge, Token Creator and Launch projects.",
    logo: "images/portfolio/unbit.svg",
    href: "https://www.unibit.app/",
    tweet: "https://twitter.com/Unibit_bridge",
    linkedin: "https://www.linkedin.com/company/unibitbridge/",
  },
  {
    id: 15,
    name: "Eternal AI",
    content:
      "Eternal AI is an open AI infrastructure on Bitcoin that benefits all humankind and is owned by none.",
    logo: "images/portfolio/eternal.svg",
    href: "https://eternalai.org/",
    tweet: "https://twitter.com/@CryptoEternalAI",
    linkedin: "https://eternalai.org/",
  },
  {
    id: 16,
    name: "Cluster Protocol",
    content:
      "Proof of compute protocol and Open Source Community for decentralized AI models.",
    logo: "images/portfolio/cluster.svg",
    href: "https://www.clusterprotocol.io/",
    tweet: "https://twitter.com/ClusterProtocol",
    linkedin: "https://www.clusterprotocol.io/",
  },

  {
    id: 17,
    name: "Interlock",
    content:
      "Building blockchain-based security products to protect DeFi users. Powered by artificial intelligence that harnesses the power of the crowd.",
    logo: "images/portfolio/interlock_1.svg",
    href: "https://www.interlock.network/",
    tweet: "https://twitter.com/interlockweb3",
    linkedin: "https://www.linkedin.com/company/apozysec/",
  },
];

export default ListPortfolio;
