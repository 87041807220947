import React, { useContext } from "react";
import { ThemeContext } from "../../App";
import Layout from "../../layouts/Layout";
import ScrollToTop from "../More/ScrollToTop";

function Conditions() {
  const { activeLocal } = useContext(ThemeContext);
  return (
    <Layout>
      <ScrollToTop />
      <div className="container mx-auto mt-20">
        <h2
          data-aos="flip-up"
          data-aos-offset="100"
          data-aos-duration="300"
          className={`  font-medium section-title mb-16  ${
            activeLocal === "dark" ? " text-dark" : "text-item"
          }`}
        >
          Term & Conditions
        </h2>
        <div className="mt-10">
          <h2 className="text-text font-vbold mb-8">
            PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY BEFORE USING THIS
            SITE
          </h2>

          <p className="text-text font-vlight mb-6 text-justify">
            <span className="block">1. Information</span> CryptoViet Ventures
            Operations Ltd (“ CryptoViet Ventures ”) is registered in England
            and Wales, company registration number 10722638.
          </p>
          <p className="text-text font-vlight mb-6 text-justify">
            <span className="block">2. Terms</span> This website{" "}
            <a
              href="https://cryptoviet.ventures"
              target="_blank"
              rel="noreferrer"
              className="text-main"
            >
              https://cryptoviet.ventures
            </a>{" "}
            is owned and controlled by CryptoViet Ventures. By accessing this
            website you are agreeing to be bound by these terms of service, all
            applicable laws and regulations, and agree that you are responsible
            for compliance with any applicable local laws. If you do not agree
            with any of these terms, you are prohibited from using or accessing
            this site, and must not continue to use the website. The materials
            contained in this website are protected by applicable copyright and
            trademark law.
          </p>
          <p className="text-text font-vlight mb-6 text-justify">
            <span className="block">3. Use License</span>Permission is granted
            to temporarily download one copy of the materials (information or
            software) on CryptoViet Ventures Operations Ltd’s website for
            personal, non-commercial transitory viewing only. This is the grant
            of a license, not a transfer of title, and under this license you
            may not:modify or copy the materials; use the materials for any
            commercial purpose, or for any public display (commercial or
            non-commercial);attempt to decompile or reverse engineer any
            software contained on CryptoViet Ventures Operations Ltd’s
            website;remove any copyright or other proprietary notations from the
            materials; ortransfer the materials to another person or “mirror”
            the materials on any other server. This license shall automatically
            terminate if you violate any of these restrictions and may be
            terminated by CryptoViet Ventures Operations Ltd at any time. Upon
            terminating your viewing of these materials or upon the termination
            of this license, you must destroy any downloaded materials in your
            possession whether in electronic or printed format.
          </p>
          <p className="text-text font-vlight mb-6 text-justify">
            <span className="block">4. Disclaimer</span>The materials on
            CryptoViet Ventures Operations Ltd’s website are provided on an ‘as
            is’ basis. CryptoViet Ventures Operations Ltd makes no warranties,
            expressed or implied, and hereby disclaims and negates all other
            warranties including, without limitation, implied warranties or
            conditions of merchantability, fitness for a particular purpose, or
            non-infringement of intellectual property or other violation of
            rights. The content on our website is provided for general
            information only. It is not intended to amount to advice. Further,
            CryptoViet Ventures Operations Ltd does not warrant or make any
            representations concerning the accuracy, likely results, or
            reliability of the use of the materials on its website or otherwise
            relating to such materials or on any sites linked to this site.
          </p>
          <p className="text-text font-vlight mb-6 text-justify">
            <span className="block">5. Limitations</span>In no event shall
            CryptoViet Ventures Operations Ltd or its suppliers be liable for
            any damages (including, without limitation, damages for loss of data
            or profit, or due to business interruption) arising out of the use
            or inability to use the materials on CryptoViet Ventures Operations
            Ltd’s website, even if CryptoViet Ventures Operations Ltd or a
            CryptoViet Ventures Operations Ltd authorized representative has
            been notified orally or in writing of the possibility of such
            damage. Because some jurisdictions do not allow limitations on
            implied warranties, or limitations of liability for consequential or
            incidental damages, these limitations may not apply to you.
          </p>

          <p className="text-text font-vlight mb-6 text-justify">
            <span className="block">6. Accuracy of materials</span> While we
            endeavour to keep the information on our websites including but not
            limited to https://cryptoviet.ventures/ up to date and correct, we
            make no representations or warranties of any kind, express or
            implied, about the completeness, accuracy, reliability, suitability
            or availability with respect to the website or the information,
            products, services, or related graphics contained on the website for
            any purpose. Any reliance you place on such information is therefore
            strictly at your own risk. In no event shall CryptoViet Ventures
            Operations Ltd or CryptoViet Capital LLP be liable for any direct,
            consequential, incidental special, punitive or other damages,
            whatsoever (including without limitation, damages for loss of
            business profits, business interruption, or loss of business
            information), arising from the use of this website. CryptoViet
            Ventures Operations Ltd may make changes to the materials contained
            on its website at any time without notice. However CryptoViet
            Ventures Operations Ltd does not make any commitment to update the
            materials.
          </p>

          <p className="text-text font-vlight mb-6 text-justify">
            <span className="block">7. Links</span>At many places on our website
            you will find links to other websites; they are for your convenience
            or to provide additional information, they are not an endorsement or
            a financial promotion. CryptoViet Ventures Operations Ltd is not
            responsible for any content or reliability of the linked websites,
            and does not necessarily endorse views expressed on the linked
            website.
          </p>

          <p className="text-text font-vlight mb-6 text-justify">
            <span className="block">8. Access</span> We do not guarantee that
            our site will be secure or free from bugs or viruses. You must not
            misuse our website by knowingly introducing viruses or other
            malicious or harmful material. You must not attempt to gain
            unauthorised access to our site.
          </p>

          <p className="text-text font-vlight mb-6 text-justify">
            <span className="block">9. Modifications</span> CryptoViet Ventures
            Operations Ltd may revise these terms of service for its website at
            any time without notice. By using this website you are agreeing to
            be bound by the then current version of these terms of service.
          </p>

          <p className="text-text font-vlight mb-6 text-justify">
            <span className="block">10. Governing Law</span> These terms and
            conditions are governed by and construed in accordance with the laws
            of United Kingdom and you irrevocably submit to the exclusive
            jurisdiction of the courts in that State or location.
          </p>
        </div>
      </div>
    </Layout>
  );
}

export default Conditions;
